import React, { useEffect, useState } from "react";
import { Global } from '../../../Variables/Global';
import { GetCurrencies } from '../../../api/HttpService'
import { RiShipFill } from 'react-icons/ri';
import { FiRefreshCcw, FiRefreshCw, FiPlus, FiEdit, FiTrash2, FiEye } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FiLink } from "react-icons/fi";
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from "react-select";
import DataTable, { Alignment } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import dateFormat from 'dateformat';
import { UncontrolledTooltip } from "reactstrap";
import { getDecodedToken } from '../../../utils/utils';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import axiosInstance from '../../../api/axiosConfig';
import ExportDropdown from './ExportDropdown';

const Shipments = () => {
    const pId = useParams();
    const [activeShipments, setActiveShipments] = useState([]);
    const [dischargedShipments, setDischargedShipments] = useState([]);
    const [newOrders, setNewOrders] = useState([]);
    const [settings, setSettings] = useState();
    const navigate = useNavigate();

    const [shipment, setShipment] = useState({
        supplierId: 0,
        supplierProformaId: 0,
        supplierCommercialInvoiceId: 0,
        blContainersRef: "",
        containerNumber: "",
        shippingLine: "",
        userId: localStorage.getItem("uTsReD"),
        shippingLineId: 0,
        companyId: localStorage.getItem("C0m8N!"),
        incotermId: 0,
        incotermName: "",
        clientInvoiceId: 0,
        clientInvoiceNumber: "",
        clientId: 0,
        clientName: "",
    });



    const [selectedCommercialInvoices, setSelectedCommercialInvoices] = useState([{
        supplierCommercialInvoiceId: 0,
        commercialInvoiceNumber: '',
    }]);
    // const [selectedCommercialInvoicesIds, setSelectedCommercialInvoicesIds] = useState([]);
    const [availableCommercialInvoices, setAvailableCommercialInvoices] = useState([]);
    const handleCommercialInvoiceAdd = () => {
        if (availableCommercialInvoices.length == 0 || supplierCommercialInvoices.length == selectedCommercialInvoices.length) {
            setAddAnotherCommercialInvoiceButtonDisabled(true);
            return;
        }
        setAddAnotherCommercialInvoiceButtonDisabled(false);
        setSelectedCommercialInvoices([
            ...selectedCommercialInvoices,
            {
                supplierCommercialInvoiceId: 0,
                commercialInvoiceNumber: '',
            }
        ]);
        if (availableCommercialInvoices.length == 0 || supplierCommercialInvoices.length == selectedCommercialInvoices.length) {
            setAddAnotherCommercialInvoiceButtonDisabled(true);
        } else {
            setAddAnotherCommercialInvoiceButtonDisabled(false);
        }
    }

    const [availableSupplierCommercialInvoicesByTrackingInfo, setAvailableSupplierCommercialInvoicesByTrackingInfo] = useState([]);
    const handleLinkCommercialInvoicesAdd = () => {
        if (availableSupplierCommercialInvoicesByTrackingInfo.length == 0 || supplierCommercialInvoicesByTrackingInfo.length == selectedCommercialInvoices.length) {
            setAddAnotherCommercialInvoiceButtonDisabled(true);
            return;
        }
        setAddAnotherCommercialInvoiceButtonDisabled(false);
        setSelectedCommercialInvoices([
            ...selectedCommercialInvoices,
            {
                supplierCommercialInvoiceId: 0,
                commercialInvoiceNumber: '',
            }
        ]);

        if (availableSupplierCommercialInvoicesByTrackingInfo.length == 0 || supplierCommercialInvoicesByTrackingInfo.length == selectedCommercialInvoices.length) {
            setAddAnotherCommercialInvoiceButtonDisabled(true);
        } else {
            setAddAnotherCommercialInvoiceButtonDisabled(false);
        }
    }

    const [order, setOrder] = useState({
        supplierId: 0,
        supplierProformaId: 0,
        supplierCommercialInvoiceId: 0,
        userId: localStorage.getItem("uTsReD"),
        companyId: localStorage.getItem("C0m8N!"),
        incotermId: 0,
        incotermName: "",
        clientInvoiceId: 0,
        clientInvoiceNumber: "",
        clientId: 0,
        clientName: "",
    });

    const [editingTrackingInfoId, setEditingTrackingInfoId] = useState(0);
    const [editingShipment, setEditingShipment] = useState({
        trackingInfoId: "",
        supplierId: 0,
        supplierProformaId: 0,
        supplierCommercialInvoiceId: 0,
        blContainersRef: "",
        containerNumber: "",
        shippingLine: "",
        userId: localStorage.getItem("uTsReD"),
        shippingLineId: 0,
        companyId: localStorage.getItem("C0m8N!"),
        incotermId: 0,
        incotermName: "",
        clientInvoiceId: 0,
        clientInvoiceNumber: "",
        clientId: 0,
        clientName: "",
    });


    const [deletingTrackingInfoId, setDeletingTrackingInfoId] = useState(0);
    const [deletingBLNumber, setDeletingBLNumber] = useState("");
    const [showDeleteShipmentModal, setShowDeleteShipmentModal] = useState(false);

    const submitDeleteShipmentForm = (e) => {
        e.preventDefault();
        var config = {
            method: 'delete',
            url: "TrackingInfo/DeleteShipment",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                trackingInfoId: deletingTrackingInfoId,
                userId: localStorage.getItem("uTsReD"),
            }
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Shipment deleted Successfully")
                    handleCloseDeleteShipmentModal();
                    setActiveShipments(response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }

    const [linkShipmentToCommercial, setLinkShipmentToCommercial] = useState({
        trackingInfoId: 0,
        supplierCommercialInvoiceId: 0,
        // userId: localStorage.getItem("uTsReD")
    });

    const trackingOptions = [
        {
            id: 1,
            label: "Container Number"
        },
        {
            id: 2,
            label: "MBL / Booking Number"
        }
    ];

    const [selectedTrackingOption, setSelectedTrackingOption] = useState(2);

    const GetActiveShipments = () => {
        var companyId = localStorage.getItem("C0m8N!");
        var userId = localStorage.getItem("uTsReD");
        var config = {
            method: 'get',
            url: "TrackingInfo/GetShipments",
            headers: {},
            params: {
                "CompanyId": companyId,
                "UserId": userId,
                "ShipmentsStatus": 1 //Active
            }
        };
        // companyId: localStorage.getItem("C0m8N!")
        axiosInstance(config)
            .then(function (response) {
                setActiveShipments(response.data);
                GetNewOrders();
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                GetNewOrders();
                setLoading(false);
            });
    }

    const GetDischargedShipments = () => {
        var companyId = localStorage.getItem("C0m8N!");
        var userId = localStorage.getItem("uTsReD");
        var config = {
            method: 'get',
            url: "TrackingInfo/GetShipments",
            headers: {},
            params: {
                "CompanyId": companyId,
                "UserId": userId,
                "ShipmentsStatus": 2 //Discharged
            }
        };
        // companyId: localStorage.getItem("C0m8N!")
        axiosInstance(config)
            .then(function (response) {
                setDischargedShipments(response.data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    const GetNewOrders = () => {
        setLoading(true);
        var companyId = localStorage.getItem("C0m8N!");
        var userId = localStorage.getItem("uTsReD");
        var config = {
            method: 'get',
            url: "TrackingInfo/GetShipments",
            headers: {},
            params: {
                "CompanyId": companyId,
                "UserId": userId,
                "ShipmentsStatus": 3 //New Orders
            }
        };
        // companyId: localStorage.getItem("C0m8N!")
        axiosInstance(config)
            .then(function (response) {
                setNewOrders(response.data);
                GetDischargedShipments();
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                GetDischargedShipments();
                setLoading(false);
            });
    }

    const GetAppSettings = () => {
        var companyId = localStorage.getItem("C0m8N!");
        var config = {
            method: 'get',
            url: "Settings/GetAppSettings",
            headers: {},
            params: { companyId }
        };
        // companyId: localStorage.getItem("C0m8N!")
        axiosInstance(config)
            .then(function (response) {
                setSettings(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const reloadShipments = () => {
        var companyId = localStorage.getItem("C0m8N!");
        var userId = localStorage.getItem("uTsReD");
        var config = {
            method: 'get',
            url: "TrackingInfo/ReloadAllShipments",
            headers: {},
            params: { companyId, userId }
        };
        // companyId: localStorage.getItem("C0m8N!")
        axiosInstance(config)
            .then(function (response) {
                setActiveShipments(response.data);
                GetAppSettings();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [suppliers, setSuppliers] = useState([])
    const GetSuppliers = () => {
        var config = {
            method: 'get',
            url: "Supplier/GetCompanySuppliersForUntrackedInvoices/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setSuppliers(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [incoterms, setIncoterms] = useState([])
    const GetIncoterms = () => {
        var config = {
            method: 'get',
            url: "Incoterms/GetIncoterms/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setIncoterms(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [shippingLines, setShippingLines] = useState([])
    const GetShippingLines = () => {
        var config = {
            method: 'get',
            url: "ShippingLine/GetEnabledShippingLinesByCompany/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setShippingLines(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleSupplierChange = (supplierId) => {
        GetSupplierProformasSupplierId(supplierId);
        GetSupplierCommercialInvoicesBySupplierId(supplierId);
        setSelectedCommercialInvoices([{
            supplierCommercialInvoiceId: 0,
            commercialInvoiceNumber: '',
        }]);
        setAddAnotherCommercialInvoiceButtonDisabled(false);
        // setSelectedCommercialInvoicesIds([]);
    }

    const handleSupplierProformaChange = (supplierOrderId, eraseEditinigShipment) => {
        GetClientProformasForSupplierProformaId(supplierOrderId);
        setShipment((prevState) => ({
            ...prevState,
            supplierCommercialInvoiceId: 0,
            clientInvoiceId: 0,
            clientInvoiceNumber: "",
            clientId: 0,
            clientName: "",
        }));

        setSelectedCommercialInvoices([{
            supplierCommercialInvoiceId: 0,
            commercialInvoiceNumber: '',
        }]);
        setAddAnotherCommercialInvoiceButtonDisabled(false);
        // setSelectedCommercialInvoicesIds([]);

        if (eraseEditinigShipment === true) {
            setEditingShipment((prevState) => ({
                ...prevState,
                supplierCommercialInvoiceId: 0,
                clientInvoiceId: 0,
                clientInvoiceNumber: "",
                clientId: 0,
                clientName: "",
            }));
        }
    }

    const [addAnotherCommercialInvoiceButtonDisabled, setAddAnotherCommercialInvoiceButtonDisabled] = useState(false);
    const handleSupplierCommercialChange = () => {
        setShipment((prevState) => ({
            ...prevState,
            supplierProformaId: 0,
            clientInvoiceId: 0,
            clientInvoiceNumber: "",
            clientId: 0,
            clientName: "",
        }));
        setEditingShipment((prevState) => ({
            ...prevState,
            supplierProformaId: 0,
            clientInvoiceId: 0,
            clientInvoiceNumber: "",
            clientId: 0,
            clientName: "",
        }));

        if (showLinkCommercialModal === true) {
            var ids = selectedCommercialInvoices.map((inv) => inv.supplierCommercialInvoiceId);
            // setSelectedCommercialInvoicesIds(ids);
            var availableList = supplierCommercialInvoicesByTrackingInfo.filter(function (item) {
                return ids.indexOf(item.sci.commercialInvoiceId) === -1;
            })

            setAvailableSupplierCommercialInvoicesByTrackingInfo(availableList);
            if (availableList.length == 0 || supplierCommercialInvoicesByTrackingInfo.length == selectedCommercialInvoices.length) {
                setAddAnotherCommercialInvoiceButtonDisabled(true);
            } else {
                setAddAnotherCommercialInvoiceButtonDisabled(false);
            }
        } else {
            var ids = selectedCommercialInvoices.map((inv) => inv.supplierCommercialInvoiceId);
            // setSelectedCommercialInvoicesIds(ids);
            var availableList = supplierCommercialInvoices.filter(function (item) {
                return ids.indexOf(item.sci.commercialInvoiceId) === -1;
            })

            setAvailableCommercialInvoices(availableList);
            if (availableList.length == 0 || supplierCommercialInvoices.length == selectedCommercialInvoices.length) {
                setAddAnotherCommercialInvoiceButtonDisabled(true);
            } else {
                setAddAnotherCommercialInvoiceButtonDisabled(false);
            }
        }
    }

    const deleteSelectedCommercialInvoice = (id) => {
        var list = selectedCommercialInvoices.filter(function (item) {
            return item.supplierCommercialInvoiceId != id;
        });
        setSelectedCommercialInvoices(list);
        var ids = list.map((inv) => inv.supplierCommercialInvoiceId);
        if (showLinkCommercialModal === true) {
            var availableList = supplierCommercialInvoicesByTrackingInfo;
            if (ids != null && ids.length > 0) {
                availableList = supplierCommercialInvoicesByTrackingInfo.filter(function (item) {
                    return ids.indexOf(item.sci.commercialInvoiceId) === -1;
                })
            }

            setAvailableSupplierCommercialInvoicesByTrackingInfo(availableList);
            if (availableList.length == 0 || supplierCommercialInvoicesByTrackingInfo.length == list.length) {
                setAddAnotherCommercialInvoiceButtonDisabled(true);
            } else {
                setAddAnotherCommercialInvoiceButtonDisabled(false);
            }
        } else {
            var availableList = supplierCommercialInvoices.filter(function (item) {
                return ids.indexOf(item.sci.commercialInvoiceId) === -1;
            })

            setAvailableCommercialInvoices(availableList);
            if (availableList.length == 0 || supplierCommercialInvoices.length == list.length) {
                setAddAnotherCommercialInvoiceButtonDisabled(true);
            } else {
                setAddAnotherCommercialInvoiceButtonDisabled(false);
            }
        }
        // handleSupplierCommercialChange();
    }


    const handleLinkShipmentToCommercial = (trackingInfoId) => {
        GetSupplierCommercialInvoicesByTrackingInfoId(trackingInfoId);
    }

    const [supplierCommercialInvoicesByTrackingInfo, setSupplierCommercialInvoicesByTrackingInfo] = useState([])
    const GetSupplierCommercialInvoicesByTrackingInfoId = (trackingInfoId) => {
        var config = {
            method: 'get',
            url: "SupplierCommercialInvoice/GetUntrackedSupplierCommercialInvoicesByTrackingInfoId/" + trackingInfoId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierCommercialInvoicesByTrackingInfo(response.data);
                setAvailableSupplierCommercialInvoicesByTrackingInfo(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [supplierProformas, setSupplierProformas] = useState([])
    const GetSupplierProformasSupplierId = (supplierId) => {
        var config = {
            method: 'get',
            url: "SupplierOrder/GetUntrackedSupplierOrdersBySupplierId/" + supplierId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierProformas(response.data);
                // props.setProducts(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [clientProformas, setClientProformas] = useState([])
    const GetClientProformasForSupplierProformaId = (supplierProformaId) => {
        var config = {
            method: 'get',
            url: "CustomerOrder/GetCustomerOrdersFromSupplierProformaId/" + supplierProformaId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setClientProformas(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const trackingForOptions = [
        {
            id: 1,
            label: "Proforma"
        },
        {
            id: 2,
            label: "Commercial Invoice"
        },
    ];

    const [selectedTrackingForOption, setSelectedTrackingForOption] = useState(1);

    const [supplierCommercialInvoices, setSupplierCommercialInvoices] = useState([])
    const GetSupplierCommercialInvoicesBySupplierId = (supplierId) => {
        var config = {
            method: 'get',
            url: "SupplierCommercialInvoice/GetUntrackedSupplierCommercialInvoicesBySupplierId/" + supplierId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierCommercialInvoices(response.data);
                setAvailableCommercialInvoices(response.data);
                // props.setProducts(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const submitShipmentForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (IsValidShipment()) {
            //bl
            shipment.commercialInvoicesIds = selectedCommercialInvoices.map((i) => i.supplierCommercialInvoiceId);
            var config = {
                method: 'post',
                url: "TrackingInfo/AddShipment",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: shipment
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Shipment Added Successfully")
                        handleCloseShipmentModal();
                        setActiveShipments(response.data);
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error.response.data);
                    setSubmitDisabled(false);
                });
        } else {
            setSubmitDisabled(false);
        }
    }

    const submitOrderForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (IsValidOrder()) {
            //bl
            var config = {
                method: 'post',
                url: "TrackingInfo/AddOrder",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: order
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Order Added Successfully")
                        handleCloseOrderModal();
                        setActiveShipments(response.data);
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                    setSubmitDisabled(false);
                });
        } else {
            setSubmitDisabled(false);
        }
    }

    const submitEditShipmentForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (IsValidEditShipment()) {
            var config = {
                method: 'put',
                url: "TrackingInfo/EditShipment",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: editingShipment,
                params: {
                    trackingInfoId: editingTrackingInfoId
                }
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Shipment Updated Successfully")
                        handleCloseEditShipmentModal();
                        handleCloseLinkShipmentToOrderModal();
                        setActiveShipments(response.data);
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                    setSubmitDisabled(false);
                });
        } else {
            setSubmitDisabled(false);
        }
    }

    const submitLinkShipmentForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (linkShipmentToCommercial.trackingInfoId != null && linkShipmentToCommercial.trackingInfoId > 0 && selectedCommercialInvoices.length > 0) {
            linkShipmentToCommercial.commercialInvoicesIds = selectedCommercialInvoices.map((i) => i.supplierCommercialInvoiceId);
            var config = {
                method: 'put',
                url: "TrackingInfo/LinkShipmentToCommercialInvoice",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: linkShipmentToCommercial
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Shipment Linked Successfully")
                        handleCloseLinkCommercialModal();
                        GetActiveShipments();
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                    setSubmitDisabled(false);
                });
        } else {
            setSubmitDisabled(false);
        }
    }

    const IsValidShipment = () => {
        if (shipment.supplierId === 0 && shipment.supplierCommercialInvoiceId === 0 && shipment.shippingLine === "" && shipment.blContainersRef === "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (shipment.supplierId === 0) {
            toast.error("Select the supplier.");
            return false;
        }
        else if (selectedTrackingForOption == 1 && shipment.supplierProformaId === 0) {
            toast.error("Select the proforma invoice.");
            return false;
        }
        else if (shipment.shippingLine === "") {
            toast.error("Select the shipping line.");
            return false;
        }
        else if (selectedTrackingOption === 1 && shipment.containerNumber === "") {
            toast.error("Enter the container number.");
            return false;
        }
        else if (selectedTrackingOption === 2 && shipment.blContainersRef === "") {
            toast.error("Enter the BL reference.");
            return false;
        }
        else if (selectedTrackingOption === 0) {
            toast.error("Enter tracking details.");
            return false
        }
        else if (shipment.supplierCommercialInvoiceId === 0 && shipment.supplierProformaId > 0 && shipment.clientInvoiceId === 0) {
            toast.error("Select the client invoice.");
            return false;
        }
        else if (selectedTrackingForOption == 2 && selectedCommercialInvoices.length == 0) {
            toast.error("Select at least 1 commercial invoice.");
            return false;
        }
        else if (selectedTrackingForOption == 2 && selectedCommercialInvoices.length > 0) {
            var showError = true;
            selectedCommercialInvoices.forEach(element => {
                if (element.supplierCommercialInvoiceId > 0) {
                    showError = false;
                }
            });
            if (showError === true) {
                toast.error("Select at least 1 commercial invoice.");
                return false;
            } else {
                shipment.commercialInvoicesIds = selectedCommercialInvoices.map((i) => i.supplierCommercialInvoiceId);
            }
        }
        return true;
    }
    const IsValidOrder = () => {
        if (order.supplierId === 0 && order.supplierCommercialInvoiceId === 0 && order.supplierProformaId === 0) {
            toast.error("Enter all required fields");
            return false;
        }
        else if (order.supplierId === 0) {
            toast.error("Select the supplier.");
            return false;
        }
        else if (order.supplierCommercialInvoiceId === 0 && order.supplierProformaId === 0) {
            toast.error("Select the invoice.");
            return false;
        }
        else if (order.supplierCommercialInvoiceId === 0 && order.supplierProformaId > 0 && order.clientInvoiceId === 0) {
            toast.error("Select the client invoice.");
            return false;
        }

        return true;
    }

    const IsValidEditShipment = () => {
        if (editingShipment.supplierId === 0 && editingShipment.supplierCommercialInvoiceId === 0 && editingShipment.shippingLine === "" && editingShipment.blContainersRef === "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (editingShipment.supplierId === 0) {
            toast.error("Select the supplier.");
            return false;
        }
        else if (editingShipment.supplierCommercialInvoiceId === 0 && editingShipment.supplierProformaId === 0) {
            toast.error("Select the invoice.");
            return false;
        }
        else if (editingShipment.shippingLine === "") {
            toast.error("Select the shipping line.");
            return false;
        }
        else if (selectedTrackingOption === 1 && editingShipment.containerNumber === "") {
            toast.error("Enter the container number.");
            return false;
        }
        else if (selectedTrackingOption === 2 && editingShipment.blContainersRef === "") {
            toast.error("Enter the BL reference.");
            return false;
        }
        else if (editingShipment.supplierCommercialInvoiceId === 0 && editingShipment.supplierProformaId > 0 && editingShipment.clientInvoiceId === 0) {
            toast.error("Select the client invoice.");
            return false;
        }
        // else if(selectedTrackingOption === 0){
        //     toast.error("Enter tracking details.");
        //     return false
        // }
        return true;
    }

    const [permissions, setPermissions] = useState([]);

    const [linkingCommercialForBL, setLinkingCommercialForBL] = useState('')
    const [showLinkCommercialModal, setShowLinkCommercialModal] = useState(false)
    const handleCloseLinkCommercialModal = () => {
        setShowLinkCommercialModal(false);
    }

    const handleCloseDeleteShipmentModal = () => {
        setDeletingTrackingInfoId(0);
        setShowDeleteShipmentModal(false);
    }

    const [showShipmentModal, setShowShipmentModal] = useState(false)
    const handleCloseShipmentModal = () => {
        setShowShipmentModal(false);
    }


    const [showOrderModal, setShowOrderModal] = useState(false)
    const handleCloseOrderModal = () => {
        setShowOrderModal(false);
    }

    const [showEditShipmentModal, setShowEditShipmentModal] = useState(false)
    const handleCloseEditShipmentModal = () => {
        setShowEditShipmentModal(false);
    }
    const [showLinkShipmentToOrderModal, setShowLinkShipmentToOrderModal] = useState(false)
    const handleCloseLinkShipmentToOrderModal = () => {
        setShowLinkShipmentToOrderModal(false);
    }

    useEffect(() => {
        // var userId = localStorage.getItem("uTsReD");
        // setShipment((prevState) => ({ ...prevState, 
        //     userId: userId
        // }))
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        // GetCurrencies().then(currencies => setCurrencies(currencies));
        GetActiveShipments();
        GetAppSettings();
        GetSuppliers();
        GetShippingLines();
        GetIncoterms();
        GetUser(localStorage.getItem("uTsReD"));
        document.title = "My Shipments";
    }, [])

    const statusStyles = {
        0: { color: '#292b2c', backgroundColor: '#e9eaea' },
        20: { color: '#292b2c', backgroundColor: '#e9eaea' },
        30: { color: '#292b2c', backgroundColor: '#d3d5d6' },
        35: { color: '#41b6d9', backgroundColor: '#eff9fc' },
        40: { color: '#936b16', backgroundColor: '#fdf0d3' },
        45: { color: '#35834f', backgroundColor: '#def8e6' },
        50: { color: '#197088', backgroundColor: '#d4f1f9' },
        60: { color: '#197088', backgroundColor: '#d4f1f9' },
    };


    const [showConfirmAssignOrderToUser, setShowConfirmAssignOrderToUser] = useState(false);
    const [assigningOrder, setAssigningOrder] = useState({});
    const handleCloseConfirmAssignOrderToUser = () => {
        setShowConfirmAssignOrderToUser(false);
    }

    const AssignOrderToUser = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        var config = {
            method: 'put',
            url: "TrackingInfo/AssignOrderToUser",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                userId: localStorage.getItem("uTsReD"),
                trackingInfoId: assigningOrder.trackingInfoId,
                assignToUserId: localStorage.getItem("uTsReD"),
            }
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Order assigned successfully!")
                    GetNewOrders();
                    setSelectedTabIndex(1);
                    handleCloseConfirmAssignOrderToUser();
                    setSubmitDisabled(false);
                    // navigate(`/Logistics/ShipmentDetails/` + assigningOrder.trackingInfoId);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data);
                handleCloseConfirmAssignOrderToUser();
                setSubmitDisabled(false);
            });
    }

    // Define your columns with shimmer loaders
    const columns = [
        {
            name: 'User',
            selector: row => (
                <div className="mt-3 mb-3 text-center">
                    <div id={`tooltip${row.trackingInfoId}`} >
                        {row.profile && row.profile !== "" ? (
                            <img
                                src={row.profile}
                                alt={row.profile}
                                width="50"
                                height="50"
                                className="rounded-circle"
                            // style={{
                            //     borderRadius: '50%', // This makes the image rounded
                            // }}
                            />
                        ) : (
                            <h4
                                className="text-center rounded-circle img-thumbnail d-flex align-items-center justify-content-center"
                                style={{
                                    width: 50,
                                    height: 50,
                                    lineHeight: 50,
                                    borderRadius: '50%',
                                    margin: 0,
                                }}
                            >
                                {row.userAvatar ?? ""}
                            </h4>
                        )}
                        <UncontrolledTooltip placement="top" target={`tooltip${row.trackingInfoId}`}>
                            {row.userName}
                        </UncontrolledTooltip>
                    </div>
                </div>
            ),
            width: "70px",
        },
        {
            name: 'Shipment',
            selector: row => (
                <div className="mt-2 mb-2">
                    <span>{row.shippingLine ?? ""}</span>
                    <br />
                    {/* <span>BL <Link to={`/Logistics/ShipmentLiveTracking/${row.containerNumber}`}><b>#{row.blReferenceNo ?? "N/A"}</b></Link></span> */}
                    <span>{row.blReferenceNo ? <>BL #<b>{row.blReferenceNo} </b></> : "-"}</span>
                    <br />
                    {(row.statusId === null || row.statusId === 0) && row.shippingLineId > 0 ? (
                        <span style={{ ...statusStyles[row.statusId], borderRadius: 5, padding: '0 5px' }}><b>Pending</b></span>
                    ) : (
                        // row.statusId === 0 ? (
                        //     <button onClick={() => handleAddShipment(row)} className="btn btn-outline-primary btn-sm"><b><RiShipFill /> Add Shipment</b></button>
                        // ) : (
                        <span style={{ ...statusStyles[row.statusId], borderRadius: 5, padding: '0 5px' }}><b>{row.status}</b></span>
                        // )
                    )}
                </div>
            ),
            width: "250px",
        },
        {
            name: 'Supplier',
            selector: row => (
                <div className="mt-2 mb-2">
                    <span className="text-wrap">{row.supplierName ?? <Skeleton width={100} />}</span>
                    <br />
                    <span className="text-wrap" ><b>#{row.supplierInvoice ?? <Skeleton width={80} />}</b></span>
                    {row.orderDate && <><br /><span>{row.orderDate.split("T")[0] ?? <Skeleton width={80} />}</span></>}
                </div>
            ),
        },
        {
            name: 'Client',
            selector: row => (
                <div className="mt-2 mb-2">
                    <span className="text-wrap">{row.clientName ?? <Skeleton width={100} />}</span>
                    <br />
                    <span className="text-wrap"><b>#{row.clientCommercialInvoice ?? <Skeleton width={80} />}</b></span>
                </div>
            ),
        },
        {
            name: 'POL',
            selector: row => (
                <div className="mt-2 mb-2">
                    {row.statusId > 20 && (
                        <>
                            <span><b>{row.loadingDate ?? <Skeleton width={80} />}</b><br />{row.pol ?? <Skeleton width={100} />}</span>
                            <br /><br />
                        </>
                    )}
                </div>
            ),
        },
        {
            name: 'POD',
            selector: row => (
                <div className="mt-2 mb-2">
                    {row.statusId > 20 && (
                        <>
                            <span><b>{row.arrivalDate ?? <Skeleton width={80} />}</b><br />{row.pod ?? <Skeleton width={100} />}</span>
                            {row.firstETA && row.firstETA !== row.arrivalDate && (
                                <>
                                    <br />
                                    <span className="text-danger"><b>First ETA: {row.firstETA ?? <Skeleton width={80} />}</b></span>
                                </>
                            )}
                            <br /><br />
                        </>
                    )}
                </div>
            ),
        },
        {
            name: 'Actions',
            selector: row => (
                <div className="mt-2 mb-2">
                    <button onClick={() => {
                        navigate(`/Logistics/ShipmentDetails/` + row.trackingInfoId);
                    }} className="ml-1 btn btn-primary"><FiEye /> View</button>
                </div>
            ),
            width: "120px",
            ignoreRowClick: true, // prevent row click event from firing when button is clicked
            allowOverflow: true,
            button: true,
        },
    ];

    const newOrdersColumns = [
        {
            name: 'Supplier',
            selector: row => (
                <div className="mt-2 mb-2">
                    <span className="text-wrap">{row.supplierName ?? <Skeleton width={100} />}</span>
                    <br />
                    <span><b>#{row.supplierInvoiceNumber ?? <Skeleton width={80} />}</b></span>
                </div>
            ),
        },
        {
            name: 'Client',
            selector: row => (
                <div className="mt-2 mb-2">
                    <span className="text-wrap">{row.clientName ?? <Skeleton width={100} />}</span>
                    <br />
                    <span className="text-wrap"><b>#{row.clientInvoiceNumber ?? <Skeleton width={80} />}</b></span>
                </div>
            ),
        },
        {
            name: 'Order Date',
            selector: row => (
                <div className="mt-2 mb-2">
                    <span>{row.orderDate ? row.orderDate.split("T")[0] : <Skeleton width={80} />}</span>
                </div>
            ),
        },
        {
            name: 'Actions',
            selector: row => (
                <div className="mt-2 mb-2">
                    <button onClick={() => {
                        navigate(`/Logistics/ShipmentDetails/` + row.trackingInfoId);
                    }} className="ml-1 btn btn-primary"><FiEye /> View</button>
                    <button
                        disabled={submitDisabled}
                        onClick={() => {
                            setAssigningOrder(row);
                            setShowConfirmAssignOrderToUser(true);
                        }} className="ml-1 btn btn-danger"><i className="fas fa-user-plus"></i> <b>Assign To Me</b></button>
                </div>
            ),
            width: "260px",
            ignoreRowClick: true, // prevent row click event from firing when button is clicked
            allowOverflow: true,
            button: true,
        },
    ];
    const [selectedTabIndex, setSelectedTabIndex] = useState(1);
    const [loading, setLoading] = useState(true);

    const viewDetails = (row) => {
        // Logic to view details for the specific row
        console.log('Viewing details for:', row);
    };
    const handleAddShipment = (row) => {
        setEditingTrackingInfoId(row.trackingInfoId);
        setEditingShipment(prevState => ({
            ...prevState,
            supplierId: row.supplierId,
            supplierName: row.supplierName,
            supplierProformaId: row.supplierOrderId,
            supplierInvoice: row.supplierInvoice,
            supplierCommercialInvoiceId: row.supplierCommercialInvoiceId,
            blContainersRef: row.blReferenceNo,
            containerNumber: row.containerNumber,
            shippingLine: row.shippingLine,
            userId: localStorage.getItem("uTsReD"),
            shippingLineId: row.shippingLineId,
            companyId: localStorage.getItem("C0m8N!"),
            incotermId: row.incotermId,
            incotermName: row.incotermName,
            statusId: row.statusId,
            notes: row.notes,
            clientId: row.clientId,
            clientName: row.clientName,
            clientInvoiceId: row.clientCommercialInvoiceId,
            clientInvoiceNumber: row.clientCommercialInvoice,
        }));
        setSelectedTrackingOption(2);
        setSelectedTrackingForOption(1);
        setShowLinkShipmentToOrderModal(true);
    };
    const [popupVisible, setPopupVisible] = useState(false);
    const [exportAll, setExportAll] = useState(false);
    const [selectedExportClient, setSelectedExportClient] = useState(null);
    const [selectedExportClientName, setSelectedExportClientName] = useState(null);
    const handleClosePopup = () => {
        setPopupVisible(false);
    };
    const distinctClients = [...new Set(activeShipments.map(shipment => shipment.clientId))];

    const [user, setUser] = useState("");
    const GetUser = (userId) => {
        var config = {
            method: 'get',
            url: 'User/GetUser/' + userId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            }
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    setUser(() => response.data);

                }
            })
            .catch(function (error) {
                toast.error(error)
            });
    }

    const DownloadCSV = () => {
        setSubmitDisabled(true);
        const headers = { "Content-Type": "blob" };
        var config = {
            method: 'post',
            url: 'TrackingInfo/DownloadActiveShipmentsCSVNew',
            responseType: "arraybuffer",
            headers,
            params: {
                'ExportClient': selectedExportClient,
                'ExportUser': localStorage.getItem("uTsReD"),
                'ExportAll': exportAll
            }
        };

        axiosInstance(config)
            .then(function (response) {
                const currentDate = new Date().toLocaleDateString('en-GB').replace(/\//g, '-');
                const outputFilename = `${selectedExportClientName ? selectedExportClientName : user.fullName} Shipments ${currentDate}.xlsx`;

                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", outputFilename);
                document.body.appendChild(link);
                link.click();
                setPopupVisible(false);
                setSubmitDisabled(false);
                setExportAll(false);
                setSelectedExportClient(null);
                setSelectedExportClientName(null);
            })
            .catch(function (error) {
                console.log(error);
                setPopupVisible(false);
                setSubmitDisabled(false);
                setExportAll(false);
                setSelectedExportClient(null);
                setSelectedExportClientName(null);
            });

    }

    useEffect(() => {
        if (exportAll) {
            DownloadCSV();
        }
    }, [exportAll]);

    return (
        <>
            {permissions.viewShipments == null || permissions.viewShipments == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="page-title-box">
                                        <h4>List of Shipments</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">List of Shipments</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className='col-sm-8 text-right'>
                                    <div className='flex justify-end'>
                                        {permissions.viewDashboard == true && (
                                            <button onClick={() => {
                                                reloadShipments();
                                            }} className="ml-1 btn btn-secondary"><FiRefreshCw /> Refresh</button>
                                        )}
                                        <span style={{ marginLeft: '15px' }}>
                                            <ExportDropdown
                                                permissions={permissions}
                                                setExportAll={setExportAll}
                                                DownloadCSV={DownloadCSV}
                                                setPopupVisible={setPopupVisible}
                                            />
                                        </span>
                                        {permissions.addShipment == true && (
                                            <button onClick={() => {
                                                // setSelectedTrackingOption(0);
                                                // setSelectedTrackingForOption(0);
                                                setSelectedTrackingOption(2);
                                                setSelectedTrackingForOption(1);
                                                setShipment({
                                                    supplierId: 0,
                                                    supplierProformaId: 0,
                                                    supplierCommercialInvoiceId: 0,
                                                    blContainersRef: "",
                                                    containerNumber: "",
                                                    shippingLine: "",
                                                    userId: localStorage.getItem("uTsReD"),
                                                    shippingLineId: 0,
                                                    companyId: localStorage.getItem("C0m8N!"),
                                                    incotermId: 0,
                                                    incotermName: "",
                                                    clientInvoiceId: 0,
                                                    clientInvoiceNumber: "",
                                                    clientId: 0,
                                                    clientName: "",
                                                });
                                                setShowShipmentModal(true);
                                            }} className="ml-1 btn btn-primary"><FiPlus /> Add Shipment</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <Modal size="lg" show={popupVisible} onHide={handleClosePopup} backdrop="static">
                                <Modal.Header closeButton>
                                    <Modal.Title>Select a Client</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="mb-3 row">
                                        <div className="col-12">
                                            <label>Client</label>
                                            <Select
                                                required
                                                placeholder="-- Select a Client --"
                                                isSearchable={true}
                                                options={distinctClients.map(clientId => ({
                                                    value: clientId,
                                                    label: activeShipments.find(shipment => shipment.clientId === clientId).clientName
                                                }))}
                                                onChange={(selectedOption) => {
                                                    setSelectedExportClient(selectedOption.value);
                                                    setSelectedExportClientName(selectedOption.label);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClosePopup}>
                                        Close
                                    </Button>
                                    <Button variant="primary" disabled={submitDisabled} onClick={DownloadCSV}>
                                        Export
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <div className="row" style={{ height: 20 }}>
                                <div className='col-sm-12 text-right' style={{ marginTop: -20 }}>
                                    <div className='flex justify-end'>
                                        {settings != null && settings.shipmentsLastUpdatedAt != null && (
                                            <span>Last update: {dateFormat((settings.shipmentsLastUpdatedAt), "dddd, mmmm dS, yyyy  HH:MM")} (UTC)</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ul class="nav nav-tabs mt-1">
                                    <li class="nav-item">
                                        <a class={(selectedTabIndex === 1 ? "active nav-link" : "nav-link")} href="#" onClick={() => {
                                            setSelectedTabIndex(1);
                                        }} style={{ cursor: 'pointer;' }}>{selectedTabIndex === 1 ? <b>Active</b> : "Active"}</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class={(selectedTabIndex === 2 ? "active nav-link" : "nav-link")} href="#" onClick={() => {
                                            setSelectedTabIndex(2);
                                        }} style={{ cursor: 'pointer;' }}>{selectedTabIndex === 2 ? <b>Discharged</b> : "Discharged"}</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class={(selectedTabIndex === 3 ? "active nav-link" : "nav-link")} href="#" onClick={() => {
                                            setSelectedTabIndex(3);
                                        }} style={{ cursor: 'pointer;' }}>
                                            {selectedTabIndex === 3 ?
                                                <b>
                                                    <span style={{ display: 'flex', alignItems: 'center' }}>New Orders {newOrders.length > 0 && <span style={{ marginLeft: '5px' }} class="rounded-pill bg-danger badge bg-danger">{newOrders.length}</span>}</span>
                                                </b>
                                                :
                                                <span style={{ display: 'flex', alignItems: 'center' }}>New Orders {newOrders.length > 0 && <span style={{ marginLeft: '5px' }} class="rounded-pill bg-danger badge bg-danger">{newOrders.length}</span>}</span>
                                            }</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="row" >
                                <div className="col-12 mt-3">
                                    {loading ? (
                                        <Skeleton count={10} height={80} />
                                    ) : (
                                        <DataTableExtensions
                                            columns={selectedTabIndex === 3 ? newOrdersColumns : columns}
                                            data={selectedTabIndex === 1 ? activeShipments : (selectedTabIndex === 2 ? dischargedShipments : newOrders)}
                                            print={false}
                                            export={false}
                                        >
                                            <DataTable
                                                noHeader
                                                pagination
                                                paginationPerPage={50}
                                                paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                                highlightOnHover
                                                customStyles={{
                                                    rows: {
                                                        style: {
                                                            minHeight: '72px', // override the row height
                                                        },
                                                    },
                                                    headCells: {
                                                        style: {
                                                            paddingLeft: '8px', // override the cell padding for head cells
                                                            paddingRight: '8px',
                                                        },
                                                    },
                                                    cells: {
                                                        style: {
                                                            paddingLeft: '8px', // override the cell padding for data cells
                                                            paddingRight: '8px',
                                                        },
                                                    },
                                                }}
                                            />
                                        </DataTableExtensions>
                                    )}
                                </div>
                            </div>


                        </div>
                    </div>
                </>)
            }

            <Modal size="lg" show={showShipmentModal} onHide={handleCloseShipmentModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Shipment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-6">
                            <label>Shipping Line</label>
                            <Select
                                required
                                placeholder="-- Select Shipping Line --"
                                isSearchable={true}
                                options={shippingLines.map(l => ({
                                    value: l,
                                    label: l.name
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setShipment((prevState) => ({
                                        ...prevState,
                                        shippingLine: e.value.name,
                                        shippingLineId: e.value.shippingLineId
                                    }))
                                    // handleSupplierInvoiceChange(e.value);
                                }}>
                            </Select>
                        </div>
                        <div className="col-6">
                            <label>BL Reference Number</label>
                            <input className="form-control"
                                type="text"
                                maxLength={20}
                                // disabled={selectedTrackingOption!=2}
                                placeholder='Enter BL number'
                                value={shipment.blContainersRef}
                                onChange={(e) => {
                                    setShipment((prevState) => ({
                                        ...prevState,
                                        blContainersRef: e.target.value.trim()
                                    }))
                                }} required />
                        </div>
                        {/* <div className="col-6">
                            <label>Track with</label>
                            <Select
                                required
                                placeholder="-- Select Tracking Option --"
                                isSearchable={true}
                                options={trackingOptions.map(l => ({
                                    value: l,
                                    label: l.label
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setSelectedTrackingOption(e.value.id);
                                }}>
                            </Select>
                        </div> */}
                    </div>
                    {/* <div className="mb-3 row">
                        {
                            selectedTrackingOption === 2 &&
                            <div className="col-6">
                                <label>BL Reference Number</label>
                                <input className="form-control"
                                    type="text"
                                    maxLength={20}
                                    // disabled={selectedTrackingOption!=2}
                                    placeholder='Enter BL number'
                                    value={shipment.blContainersRef}
                                    onChange={(e) => {
                                        setShipment((prevState) => ({
                                            ...prevState,
                                            blContainersRef: e.target.value.trim()
                                        }))
                                    }} required />
                            </div>
                        }
                        {
                            selectedTrackingOption === 1 &&
                            <div className="col-6">
                                <label>Container Number</label>
                                <input className="form-control"
                                    type="text"
                                    // disabled={selectedTrackingOption!=1}
                                    placeholder='Enter container number'
                                    value={shipment.containerNumber}
                                    onChange={(e) =>
                                        setShipment((prevState) => ({
                                            ...prevState,
                                            containerNumber: e.target.value.trim()
                                        }))
                                    } required />
                            </div>
                        }

                    </div> */}
                    <br />

                    <div className="mb-3 row">
                        <div className="col-6">
                            <label>Incoterms</label>
                            <Select
                                required
                                placeholder="-- Select Incoterms --"
                                isSearchable={true}
                                options={incoterms.map(i => ({
                                    value: i,
                                    label: i.name
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setShipment((prevState) => ({
                                        ...prevState,
                                        incotermId: e.value.incotermId,
                                        incotermName: e.value.name
                                    }))
                                }}>
                            </Select>
                        </div>
                    </div>
                    <br />
                    <div className="mb-3 row">
                        <div className="col-6">
                            <label>Supplier</label>
                            <Select
                                required
                                placeholder="-- Select Supplier --"
                                isSearchable={true}
                                options={suppliers.map(c => ({
                                    value: c,
                                    label: c.supplierName
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setShipment((prevState) => ({
                                        ...prevState,
                                        supplierId: e.value.supplierId
                                    }))
                                    handleSupplierChange(e.value.supplierId);
                                }}>
                            </Select>
                        </div>
                        {/*<div className="col-6">*/}
                        {/*    <label>Supplier Proforma</label>*/}
                        {/*    <Select*/}
                        {/*        required*/}
                        {/*        placeholder="-- Select Proforma --"*/}
                        {/*        isSearchable={true}*/}
                        {/*        options={supplierProformas.map(p => ({*/}
                        {/*            value: p.jSupplierOrder,*/}
                        {/*            label: "#" + p.jSupplierOrder.invoiceNumber*/}
                        {/*        }))}*/}
                        {/*        valuekey*/}
                        {/*        onChange={(e) => {*/}
                        {/*            setShipment((prevState) => ({*/}
                        {/*                ...prevState,*/}
                        {/*                supplierProformaId: e.value.supplierOrderId*/}
                        {/*            }))*/}
                        {/*            handleSupplierProformaChange(e.value.supplierOrderId, true);*/}
                        {/*        }}>*/}
                        {/*    </Select>*/}
                        {/*</div>*/}
                        <div className="col-6">
                            <label>Invoice Type</label>
                            <Select
                                required
                                placeholder="-- Select Option --"
                                isSearchable={true}
                                options={trackingForOptions.map(i => ({
                                    value: i.id,
                                    label: i.label
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setSelectedTrackingForOption(
                                        e.value
                                    );
                                }}>
                            </Select>
                        </div>
                    </div>
                    {
                        selectedTrackingForOption === 1 &&
                        <div className="mb-3 row">
                            <div className="col-6">
                                <label>Supplier Proforma</label>
                                <Select
                                    required
                                    // isDisabled={selectedTrackingForOption!=1}
                                    placeholder="-- Select Proforma --"
                                    isSearchable={true}
                                    options={supplierProformas.map(p => ({
                                        value: p.jSupplierOrder,
                                        label: "#" + p.jSupplierOrder.invoiceNumber
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        setShipment((prevState) => ({
                                            ...prevState,
                                            supplierProformaId: e.value.supplierOrderId
                                        }))
                                        handleSupplierProformaChange(e.value.supplierOrderId, true);
                                    }}>
                                </Select>
                            </div>
                        </div>
                    }
                    {
                        selectedTrackingForOption === 2 &&
                        <div>
                            <label className=" mt-3">Supplier Commercial Invoices</label>
                            <div>
                                {
                                    selectedCommercialInvoices.map((co, index) => (
                                        <div key={index}>
                                            <div className='mb-3 row'>
                                                <div className="col-md-6 mb-1 mt-1">
                                                    <Select
                                                        required
                                                        // isDisabled={selectedTrackingForOption!=2}
                                                        placeholder="-- Select Invoice --"
                                                        isSearchable={true}
                                                        options={availableCommercialInvoices.map(i => ({
                                                            value: i.sci,
                                                            label: "#" + i.sci.invoiceNumber
                                                        }))}
                                                        valuekey
                                                        value={co.commercialInvoiceNumber ? { value: co.supplierCommercialInvoiceId, label: co.commercialInvoiceNumber } : null}
                                                        onChange={(e) => {
                                                            const selectedCO = e.value;
                                                            co.supplierCommercialInvoiceId = selectedCO.commercialInvoiceId;
                                                            co.commercialInvoiceNumber = selectedCO.invoiceNumber;
                                                            setSelectedCommercialInvoices(prevInvoices => {
                                                                const updatedInvoices = [...prevInvoices];
                                                                updatedInvoices[index] = {
                                                                    ...updatedInvoices[index],
                                                                    supplierCommercialInvoiceId: selectedCO.commercialInvoiceId,
                                                                    commercialInvoiceNumber: selectedCO.invoiceNumber
                                                                };
                                                                return updatedInvoices;
                                                            });
                                                            handleSupplierCommercialChange();
                                                        }}

                                                    >
                                                    </Select>
                                                </div>
                                                <div className="col-md-1 mb-1 mt-1">
                                                    <button className="btn btn-danger btn-md" onClick={() => {
                                                        deleteSelectedCommercialInvoice(co.supplierCommercialInvoiceId);
                                                    }}>
                                                        <FiTrash2 />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <button disabled={addAnotherCommercialInvoiceButtonDisabled} className="waves-effect waves-light btn btn-md btn-info mr-3" onClick={() => handleCommercialInvoiceAdd()}><FiPlus /> Add Another Invoice</button>
                        </div>
                    }
                    {
                        selectedTrackingForOption === 1 && shipment.supplierProformaId != null && shipment.supplierProformaId > 0 &&
                        <div className="mb-3 row">
                            <div className="col-6">
                                <label>Client Proforma</label>
                                <Select
                                    required
                                    placeholder="-- Select Client Invoice --"
                                    isSearchable={true}
                                    options={clientProformas.map(i => ({
                                        value: i,
                                        label: "#" + i.invoiceNumber + " - " + i.customerName
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        setShipment((prevState) => ({
                                            ...prevState,
                                            clientInvoiceId: e.value.customerOrderId,
                                            clientInvoiceNumber: e.value.invoiceNumber,
                                            clientId: e.value.customerId,
                                            clientName: e.value.customerName,
                                        }))
                                    }}>
                                </Select>
                            </div>
                        </div>
                    }
                    <br />
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Notes</label>
                            <textarea className="form-control" type="text" value={shipment.notes} onChange={(e) => {
                                setShipment((prevState) => ({
                                    ...prevState,
                                    notes: e.target.value,
                                }))
                            }} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseShipmentModal}>
                        Close
                    </Button>

                    {
                        <Button variant="primary"
                            disabled={submitDisabled}
                            onClick={(e) => submitShipmentForm(e)}>
                            Save Changes
                        </Button>
                    }

                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showOrderModal} onHide={handleCloseOrderModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Supplier</label>
                            <Select
                                required
                                placeholder="-- Select Supplier --"
                                isSearchable={true}
                                options={suppliers.map(c => ({
                                    value: c,
                                    label: c.supplierName
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setOrder((prevState) => ({
                                        ...prevState,
                                        supplierId: e.value.supplierId
                                    }))
                                    handleSupplierChange(e.value.supplierId);
                                }}>
                            </Select>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Invoice Type</label>
                            <Select
                                required
                                placeholder="-- Select Option --"
                                isSearchable={true}
                                options={trackingForOptions.map(i => ({
                                    value: i.id,
                                    label: i.label
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setSelectedTrackingForOption(
                                        e.value
                                    );
                                }}>
                            </Select>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        {
                            selectedTrackingForOption === 1 &&
                            <div className="col-12">
                                <label>Supplier Proforma</label>
                                <Select
                                    required
                                    placeholder="-- Select Proforma --"
                                    isSearchable={true}
                                    options={supplierProformas.map(p => ({
                                        value: p.jSupplierOrder,
                                        label: p.jSupplierOrder.invoiceNumber
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        setOrder((prevState) => ({
                                            ...prevState,
                                            supplierProformaId: e.value.supplierOrderId
                                        }))
                                        handleSupplierProformaChange(e.value.supplierOrderId, true);
                                    }}>
                                </Select>
                            </div>
                        }
                        {
                            selectedTrackingForOption === 2 &&
                            <div className="col-12">
                                <label>Supplier Commercial Invoice</label>
                                <Select
                                    required
                                    placeholder="-- Select Invoice --"
                                    isSearchable={true}
                                    options={supplierCommercialInvoices.map(i => ({
                                        value: i.sci,
                                        label: "#" + i.sci.invoiceNumber
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        setOrder((prevState) => ({
                                            ...prevState,
                                            supplierCommercialInvoiceId: e.value.commercialInvoiceId
                                        }))
                                        handleSupplierCommercialChange();
                                    }}>
                                </Select>
                            </div>
                        }
                    </div>

                    {
                        selectedTrackingForOption === 1 && order.supplierProformaId != null && order.supplierProformaId > 0 &&
                        <div className="mb-3 row">
                            <div className="col-12">
                                <label>Client Proforma</label>
                                <Select
                                    required
                                    placeholder="-- Select Client Invoice --"
                                    isSearchable={true}
                                    options={clientProformas.map(i => ({
                                        value: i,
                                        label: "#" + i.invoiceNumber + " - " + i.customerName
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        setOrder((prevState) => ({
                                            ...prevState,
                                            clientInvoiceId: e.value.customerOrderId,
                                            clientInvoiceNumber: e.value.invoiceNumber,
                                            clientId: e.value.customerId,
                                            clientName: e.value.customerName,
                                        }))
                                    }}>
                                </Select>
                            </div>
                        </div>
                    }
                    <br />
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Incoterms</label>
                            <Select
                                required
                                placeholder="-- Select Incoterms --"
                                isSearchable={true}
                                options={incoterms.map(i => ({
                                    value: i,
                                    label: i.name
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setOrder((prevState) => ({
                                        ...prevState,
                                        incotermId: e.value.incotermId,
                                        incotermName: e.value.name
                                    }))
                                }}>
                            </Select>
                        </div>
                    </div>
                    <br />
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Notes</label>
                            <textarea className="form-control" type="text" value={order.notes} onChange={(e) => {
                                setOrder((prevState) => ({
                                    ...prevState,
                                    notes: e.target.value,
                                }))
                            }} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseOrderModal}>
                        Close
                    </Button>

                    {
                        <Button variant="primary"
                            disabled={submitDisabled}
                            onClick={(e) => submitOrderForm(e)}>
                            Save Changes
                        </Button>
                    }

                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showEditShipmentModal} onHide={handleCloseEditShipmentModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Shipment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-6">
                            <label>Supplier</label>
                            <Select
                                required
                                placeholder="-- Select Supplier --"
                                isSearchable={true}
                                defaultValue={{ label: editingShipment.supplierName, value: editingShipment.supplierId }}
                                options={suppliers.map(c => ({
                                    value: c,
                                    label: c.supplierName
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setEditingShipment((prevState) => ({
                                        ...prevState,
                                        supplierId: e.value.supplierId,
                                        supplierProformaId: 0,
                                        supplierCommercialInvoiceId: 0,
                                    }))
                                    handleSupplierChange(e.value.supplierId);
                                }}>
                            </Select>
                        </div>
                        <div className="col-6">
                            <label>Tracking for</label>
                            <Select
                                required
                                placeholder="-- Select Option --"
                                isSearchable={true}
                                options={trackingForOptions.map(i => ({
                                    value: i.id,
                                    label: i.label
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setSelectedTrackingForOption(
                                        e.value
                                    );
                                }}>
                            </Select>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        {
                            selectedTrackingForOption === 1 &&
                            <div className="col-6">
                                <label>Supplier Proforma</label>
                                <Select
                                    required
                                    // isDisabled={selectedTrackingForOption != 1}
                                    placeholder="-- Select Proforma --"
                                    isSearchable={true}
                                    defaultValue={{ label: editingShipment.supplierProformaId > 0 ? editingShipment.supplierInvoice : "", value: editingShipment.supplierProformaId ?? 0 }}
                                    options={supplierProformas.map(p => ({
                                        value: p.jSupplierOrder,
                                        label: p.jSupplierOrder.invoiceNumber
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        setEditingShipment((prevState) => ({
                                            ...prevState,
                                            supplierProformaId: e.value.supplierOrderId
                                        }))
                                        handleSupplierProformaChange(e.value.supplierOrderId, true);
                                    }}>
                                </Select>
                            </div>
                        }
                        {
                            selectedTrackingForOption === 2 &&
                            <div className="col-6">
                                <label>Supplier Commercial Invoice</label>
                                <Select
                                    required
                                    // isDisabled={selectedTrackingForOption!=2}
                                    placeholder="-- Select Invoice --"
                                    isSearchable={true}
                                    defaultValue={{ label: editingShipment.supplierCommercialInvoiceId > 0 ? editingShipment.supplierInvoice : "", value: editingShipment.supplierCommercialInvoiceId ?? 0 }}
                                    options={supplierCommercialInvoices.map(i => ({
                                        value: i.sci,
                                        label: "#" + i.sci.invoiceNumber
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        setEditingShipment((prevState) => ({
                                            ...prevState,
                                            supplierCommercialInvoiceId: e.value.commercialInvoiceId
                                        }))
                                        handleSupplierCommercialChange();
                                    }}>
                                </Select>
                            </div>
                        }
                        {
                            selectedTrackingForOption === 1 && editingShipment.supplierProformaId != null && editingShipment.supplierProformaId > 0 &&
                            <div className="col-6">
                                <label>Client Proforma</label>
                                <Select
                                    required
                                    placeholder="-- Select Client Invoice --"
                                    isSearchable={true}
                                    defaultValue={{
                                        label: editingShipment.clientInvoiceId > 0 ? ("#" + editingShipment.clientInvoiceNumber + " - " + editingShipment.clientName) : "",
                                        value: editingShipment
                                    }}
                                    options={clientProformas.map(i => ({
                                        value: i,
                                        label: "#" + i.invoiceNumber + " - " + i.customerName
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        setEditingShipment((prevState) => ({
                                            ...prevState,
                                            clientInvoiceId: e.value.customerOrderId,
                                            clientInvoiceNumber: e.value.invoiceNumber,
                                            clientId: e.value.customerId,
                                            clientName: e.value.customerName,
                                        }))
                                    }}>
                                </Select>
                            </div>
                        }
                    </div>
                    <br />
                    <div className="mb-3 row">
                        <div className="col-6">
                            <label>Incoterms</label>
                            <Select
                                required
                                placeholder="-- Select Incoterms --"
                                isSearchable={true}
                                defaultValue={{ label: editingShipment.incotermName, value: editingShipment.incotermId }}
                                options={incoterms.map(i => ({
                                    value: i,
                                    label: i.name
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setEditingShipment((prevState) => ({
                                        ...prevState,
                                        incotermId: e.value.incotermId,
                                        incotermName: e.value.name
                                    }))
                                }}>
                            </Select>
                        </div>
                    </div>
                    <br />
                    {
                        editingShipment.statusId <= 20 &&
                        <>
                            <div className="mb-3 row">
                                <div className="col-6">
                                    <label>Shipping Line</label>
                                    <Select
                                        required
                                        placeholder="-- Select Shipping Line --"
                                        isSearchable={true}
                                        isDisabled={editingShipment.statusId != null && editingShipment.statusId > 0}
                                        defaultValue={{ label: editingShipment.shippingLine, value: editingShipment.shippingLineId }}
                                        options={shippingLines.map(l => ({
                                            value: l,
                                            label: l.name
                                        }))}
                                        valuekey
                                        onChange={(e) => {
                                            setEditingShipment((prevState) => ({
                                                ...prevState,
                                                shippingLine: e.value.name,
                                                shippingLineId: e.value.shippingLineId
                                            }))
                                            // handleSupplierInvoiceChange(e.value);
                                        }}>
                                    </Select>
                                </div>
                                <div className="col-6">
                                    <label>Track with</label>
                                    <Select
                                        required
                                        placeholder="-- Select Tracking Option --"
                                        isSearchable={true}
                                        isDisabled={editingShipment.statusId > 0}
                                        options={trackingOptions.map(l => ({
                                            value: l,
                                            label: l.label
                                        }))}
                                        valuekey
                                        onChange={(e) => {
                                            setSelectedTrackingOption(e.value.id);
                                        }}>
                                    </Select>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                {
                                    selectedTrackingOption === 2 &&
                                    <div className="col-6">
                                        <label>BL Reference Number</label>
                                        <input className="form-control"
                                            type="text"
                                            // disabled={selectedTrackingOption != 2 || editingShipment.statusId > 0}
                                            placeholder='Enter BL number'
                                            value={editingShipment.blContainersRef}
                                            onChange={(e) =>
                                                setEditingShipment((prevState) => ({
                                                    ...prevState,
                                                    blContainersRef: e.target.value.trim()
                                                }))
                                            } required />
                                    </div>
                                }
                                {
                                    selectedTrackingOption === 1 &&
                                    <div className="col-6">
                                        <label>Container Number</label>
                                        <input className="form-control"
                                            type="text"
                                            // disabled={selectedTrackingOption != 1 || editingShipment.statusId > 0}
                                            placeholder='Enter container number'
                                            value={editingShipment.containerNumber}
                                            onChange={(e) =>
                                                setEditingShipment((prevState) => ({
                                                    ...prevState,
                                                    containerNumber: e.target.value.trim()
                                                }))
                                            } required />
                                    </div>
                                }
                            </div>
                            <br />
                        </>
                    }
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Notes</label>
                            <textarea className="form-control" type="text" value={editingShipment.notes} onChange={(e) => {
                                setEditingShipment((prevState) => ({
                                    ...prevState,
                                    notes: e.target.value,
                                }))
                            }} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditShipmentModal}>
                        Close
                    </Button>
                    {
                        <Button variant="primary"
                            disabled={submitDisabled}
                            onClick={(e) => submitEditShipmentForm(e)}>
                            Save Changes
                        </Button>
                    }
                </Modal.Footer>
            </Modal>


            <Modal show={showLinkShipmentToOrderModal} onHide={handleCloseLinkShipmentToOrderModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Shipment for Invoice #{editingShipment.supplierInvoice}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        {
                            editingShipment.statusId <= 20 &&
                            <div className="col-12">
                                <label>Shipping Line</label>
                                <Select
                                    required
                                    placeholder="-- Select Shipping Line --"
                                    isSearchable={true}
                                    // isDisabled={editingShipment.statusId != null && editingShipment.statusId > 0}
                                    // defaultValue={{label: editingShipment.shippingLine, value: editingShipment.shippingLineId}}
                                    options={shippingLines.map(l => ({
                                        value: l,
                                        label: l.name
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        setEditingShipment((prevState) => ({
                                            ...prevState,
                                            shippingLine: e.value.name,
                                            shippingLineId: e.value.shippingLineId
                                        }))
                                        // handleSupplierInvoiceChange(e.value);
                                    }}>
                                </Select>
                            </div>
                        }
                    </div>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Track with</label>
                            <Select
                                required
                                placeholder="-- Select Tracking Option --"
                                isSearchable={true}
                                // isDisabled={editingShipment.statusId > 0}
                                options={trackingOptions.map(l => ({
                                    value: l,
                                    label: l.label
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setSelectedTrackingOption(e.value.id);
                                }}>
                            </Select>
                        </div>
                    </div>
                    {
                        <div className="mb-3 row">
                            {
                                selectedTrackingOption === 2 &&
                                <div className="col-12">
                                    <label>BL Reference Number</label>
                                    <input className="form-control"
                                        type="text"
                                        // disabled={selectedTrackingOption != 2 || editingShipment.statusId > 0}
                                        placeholder='Enter BL number'
                                        value={editingShipment.blContainersRef}
                                        onChange={(e) =>
                                            setEditingShipment((prevState) => ({
                                                ...prevState,
                                                blContainersRef: e.target.value.trim()
                                            }))
                                        } required />
                                </div>
                            }
                            {
                                selectedTrackingOption === 1 &&
                                <div className="col-12">
                                    <label>Container Number</label>
                                    <input className="form-control"
                                        type="text"
                                        // disabled={selectedTrackingOption != 1 || editingShipment.statusId > 0}
                                        placeholder='Enter container number'
                                        value={editingShipment.containerNumber}
                                        onChange={(e) =>
                                            setEditingShipment((prevState) => ({
                                                ...prevState,
                                                containerNumber: e.target.value.trim()
                                            }))
                                        } required />
                                </div>
                            }
                        </div>
                    }
                    <br />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseLinkShipmentToOrderModal}>
                        Close
                    </Button>
                    {
                        <Button variant="primary"
                            disabled={submitDisabled}
                            onClick={(e) => submitEditShipmentForm(e)}>
                            Save Changes
                        </Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showLinkCommercialModal} onHide={handleCloseLinkCommercialModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Link BL #{linkingCommercialForBL} to Commercial Invoices</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <div className="mb-3 row">
                                        <div className="col-12">
                                            <label>Supplier Commercial Invoice</label>
                                            <Select
                                                required
                                                placeholder="-- Select Invoice --"
                                                isSearchable={true}
                                                options={supplierCommercialInvoicesByTrackingInfo.map(i => ({
                                                    value: i.sci,
                                                    label: "#" + i.sci.invoiceNumber
                                                }))}
                                                valuekey
                                                onChange={(e) => {
                                                    setLinkShipmentToCommercial((prevState) => ({ ...prevState, 
                                                        supplierCommercialInvoiceId: e.value.commercialInvoiceId
                                                    }))
                                                }}>
                                            </Select>
                                        </div>
                                    </div> */}
                    <div>
                        <label className=" mt-3">Supplier Commercial Invoices</label>
                        <div>
                            {
                                selectedCommercialInvoices.map((co, index) => (
                                    <div key={index}>
                                        <div className='mb-3 row'>
                                            <div className="col-md-10 mb-1 mt-1">
                                                <Select
                                                    required
                                                    // isDisabled={selectedTrackingForOption!=2}
                                                    placeholder="-- Select Invoice --"
                                                    isSearchable={true}
                                                    options={availableSupplierCommercialInvoicesByTrackingInfo.map(i => ({
                                                        value: i.sci,
                                                        label: "#" + i.sci.invoiceNumber
                                                    }))}
                                                    valuekey
                                                    // onChange={(e) => {
                                                    //     // setShipment((prevState) => ({ ...prevState, 
                                                    //     //     supplierCommercialInvoiceId: e.value.commercialInvoiceId
                                                    //     // }))
                                                    //     // setSelectedCommercialInvoices
                                                    //     co.supplierCommercialInvoiceId = e.value.commercialInvoiceId;
                                                    //     co.commercialInvoiceNumber = e.value.invoiceNumber;
                                                    //     handleSupplierCommercialChange();
                                                    // }}

                                                    value={co.commercialInvoiceNumber ? { value: co.supplierCommercialInvoiceId, label: co.commercialInvoiceNumber } : null}
                                                    onChange={(e) => {
                                                        const selectedCO = e.value;
                                                        co.supplierCommercialInvoiceId = selectedCO.commercialInvoiceId;
                                                        co.commercialInvoiceNumber = selectedCO.invoiceNumber;
                                                        setSelectedCommercialInvoices(prevInvoices => {
                                                            const updatedInvoices = [...prevInvoices];
                                                            updatedInvoices[index] = {
                                                                ...updatedInvoices[index],
                                                                supplierCommercialInvoiceId: selectedCO.commercialInvoiceId,
                                                                commercialInvoiceNumber: selectedCO.invoiceNumber
                                                            };
                                                            return updatedInvoices;
                                                        });
                                                        handleSupplierCommercialChange();
                                                    }}

                                                >
                                                </Select>
                                            </div>
                                            <div className="col-md-1 mb-1 mt-1">
                                                <button className="btn btn-danger btn-md" onClick={() => {
                                                    deleteSelectedCommercialInvoice(co.supplierCommercialInvoiceId);
                                                }}>
                                                    <FiTrash2 />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <button disabled={addAnotherCommercialInvoiceButtonDisabled}
                            className="waves-effect waves-light btn btn-md btn-info mr-3"
                            onClick={() => handleLinkCommercialInvoicesAdd()}><FiPlus /> Add Another Invoice</button>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseLinkCommercialModal}>
                        Close
                    </Button>
                    <Button variant="primary"
                        disabled={submitDisabled}
                        onClick={(e) => submitLinkShipmentForm(e)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showDeleteShipmentModal} onHide={handleCloseDeleteShipmentModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Shipment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Are you sure you want to delete shipment with BL #{deletingBLNumber}?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteShipmentModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => submitDeleteShipmentForm(e)}>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showConfirmAssignOrderToUser} onHide={handleCloseConfirmAssignOrderToUser} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{assigningOrder.clientName} <b>(#{assigningOrder.clientInvoiceNumber})</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Are you sure you want to assign this order to you?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseConfirmAssignOrderToUser}>
                        Cancel
                    </button>
                    <button className="btn btn-primary"
                        disabled={submitDisabled}
                        onClick={(e) => AssignOrderToUser(e)}
                    >
                        Yes, Proceed
                    </button>
                </Modal.Footer>
            </Modal>
        </>

    )
}
export default Shipments
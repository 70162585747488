import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component"
import { Global } from "../../../Variables/Global";
import { FiEdit, FiEye, FiTrash2, FiPlus } from 'react-icons/fi';
import { toast } from "react-toastify";
import axiosInstance from '../../../api/axiosConfig';
import { getDecodedToken } from '../../../utils/utils';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

const ActiveOrders = (props) => {
    const [permissions, setPermissions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const trackingStatusStyles = {
        0: { color: '#292b2c', backgroundColor: '#e9eaea' },
        20: { color: '#292b2c', backgroundColor: '#e9eaea' },
        30: { color: '#292b2c', backgroundColor: '#d3d5d6' },
        35: { color: '#41b6d9', backgroundColor: '#eff9fc' },
        40: { color: '#936b16', backgroundColor: '#fdf0d3' },
        45: { color: '#35834f', backgroundColor: '#def8e6' },
        50: { color: '#197088', backgroundColor: '#d4f1f9' },
        60: { color: '#197088', backgroundColor: '#d4f1f9' },
    };




    const GetActiveOrders = () => {
        var config = {
            method: 'get',
            url: "ActiveSalesOrder/GetActiveSalesOrders",
            // url: "ActiveSalesOrder/RecalculateProfitForSupplierCommercialInvoices",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setOrders(response.data);
                setFilteredOrders(response.data); // Initialize filteredInvoices with all invoices
                setLoading(false);

            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data.message);
                setLoading(false);
            });
    }

    // Handle search input change
    const handleSearchInputChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);

        if (value == null || value == '') {
            setFilteredOrders(orders);
        }
        // Filter invoices based on the search query
        const filtered = orders.filter(invoice =>
            invoice.supplierInvoiceNumber.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredOrders(filtered);
    }

    useEffect(() => {
        setLoading(true);
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        document.title = "Active Orders";
        GetActiveOrders();
    }, [])

    return (
        <>
            {permissions.viewAllActiveOrders == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
                    <div className="page-content">
                        <div className="container-fluid">
                            <section>
                                <div className="container-fluid">
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <input
                                                type="text"
                                                placeholder="Invoice Number"
                                                value={searchQuery}
                                                onChange={handleSearchInputChange}
                                                style={{ marginBottom: '10px' }}
                                            />
                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                                                {loading ? (
                                                    Array.from({ length: 3 }).map((_, index) => (
                                                        <div key={index} style={{
                                                            border: '1px solid #ddd',
                                                            borderRadius: '5px',
                                                            padding: '15px',
                                                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                                            flex: '1 1 100%',
                                                            boxSizing: 'border-box',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            backgroundColor: '#fff'
                                                        }}>
                                                            <Skeleton height={30} style={{ marginBottom: '10px' }} />
                                                            <Skeleton height={20} style={{ marginBottom: '10px' }} />
                                                            <Skeleton count={3} height={20} />
                                                        </div>
                                                    ))
                                                ) : (
                                                    filteredOrders.length === 0 ? (
                                                        <div>No Active Orders</div>
                                                    ) : (
                                                        filteredOrders.map(order => (
                                                            <div key={order.supplierInvoiceNumber} style={{
                                                                border: '1px solid #ddd',
                                                                borderRadius: '5px',
                                                                padding: '15px',
                                                                //boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                                                flex: '1 1 100%',
                                                                //boxSizing: 'border-box',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                backgroundColor: '#fff'
                                                            }}>

                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ flex: '1 1 25%' }}>
                                                                        <div style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                                            {order.supplierName}
                                                                        </div>
                                                                        {order.supplierInvoiceNumber != null && order.supplierInvoiceNumber !== "" && (
                                                                            <a href={`/Accounting/Supplier/${order.supplierId}/Account/${order.supplierAccountId}/Order/${order.supplierOrderId}`} style={{ textDecoration: 'none', color: '#f5b225' }}>
                                                                                <b>#{order.supplierInvoiceNumber}</b>
                                                                                <span style={{ color: '#a3a3a3', marginLeft: '5px' }}>
                                                                                    {" - " + (order.supplierInvoiceDate ? order.supplierInvoiceDate.split('T')[0] : 'No Date')}
                                                                                </span>
                                                                            </a>
                                                                        )}
                                                                        <div style={{ color: '#a3a3a3', marginTop: '5px' }}>
                                                                            {order.balance != null && order.balance <= 0 ? (
                                                                                <span style={{ color: '#35834f', backgroundColor: '#def8e6', borderColor: '#cdf4da', borderRadius: '5px', padding: '5px 10px' }}>
                                                                                    <b>Paid</b>
                                                                                </span>
                                                                            ) : (
                                                                                <span style={{ color: '#8e3241', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: '5px', padding: '5px 10px' }}>
                                                                                    <b>{order.supplierCurrencySuffix} {order.balance.toLocaleString()}</b>
                                                                                </span>
                                                                            )}
                                                                            {order.pendingFCLs > 0 && (
                                                                                <div style={{ marginTop: '5px', color: '#936b16', backgroundColor: '#fdf0d3', borderColor: '#fce8be', borderRadius: '5px', padding: '5px 10px' }}>
                                                                                    {order.pendingFCLs === 1 ? "1 Pending FCL" : `${order.pendingFCLs} Pending FCLs`}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {order.customerInvoiceNumber != null && order.customerInvoiceNumber !== "" && (
                                                                        <div style={{ flex: '1 1 25%', marginRight: '20px', alignItems: 'center' }}>
                                                                            <div style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                                                {order.customerName}
                                                                            </div>
                                                                            <a href={`/Accounting/Customer/${order.customerId}/Account/${order.customerAccountId}/View/Order/${order.customerOrderId}`} style={{ textDecoration: 'none', color: '#f5b225' }}>
                                                                                <b>#{order.customerInvoiceNumber}</b>
                                                                                <span style={{ color: '#a3a3a3', marginLeft: '5px' }}>
                                                                                    {" - " + (order.customerInvoiceDate ? order.customerInvoiceDate.split('T')[0] : 'No Date')}
                                                                                </span>
                                                                            </a>

                                                                            <div style={{ marginTop: '5px', color: '#a3a3a3' }}>
                                                                                {order.totalPrice != null && order.totalPrice != "" && (
                                                                                    <span style={{ color: '#8e3241', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: '5px', padding: '5px 10px' }}>

                                                                                        <b>{order.customerCurrencySuffix} {order.totalPrice.toLocaleString()}</b>

                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    <div style={{ flex: '1 1 25%' }}>
                                                                        <div style={{ fontWeight: 'bold', fontSize: '12px' }}>

                                                                        </div>
                                                                    </div>
                                                                    <div style={{ flex: '1 1 25%' }}>
                                                                        <div style={{ fontWeight: 'bold', fontSize: '12px' }}>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div style={{ flexGrow: 1 }}>
                                                                    {order.commercialInvoices && order.commercialInvoices.length > 0 ? (
                                                                        order.commercialInvoices.map(c => (
                                                                            <div
                                                                                key={c.supplierCommercialInvoice}
                                                                                style={{
                                                                                    marginBottom: '10px',
                                                                                    padding: '10px',
                                                                                    border: '1px solid #ddd',
                                                                                    borderRadius: '5px',
                                                                                    backgroundColor: '#f9f9f9'
                                                                                }}
                                                                            >
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <div style={{ flex: '1 1 25%' }}>
                                                                                        {c.supplierCommercialInvoice && (
                                                                                            <a
                                                                                                href={`/Accounting/Supplier/${order.supplierId}/Account/${order.supplierAccountId}/Order/${order.supplierOrderId}`}
                                                                                                style={{ textDecoration: 'none', color: '#3eb7ba' }}
                                                                                            >
                                                                                                <b>#{c.supplierCommercialInvoice}</b>
                                                                                                <span style={{ color: '#a3a3a3' }}>
                                                                                                    {" - " + (c.supplierCommercialInvoiceDate ? c.supplierCommercialInvoiceDate.split('T')[0] : 'No Date')}
                                                                                                </span>
                                                                                            </a>
                                                                                        )}
                                                                                        <div style={{ color: '#a3a3a3' }}>
                                                                                            {c.supplierCommercialInvoicebalance <= 0 ? (
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: '#35834f',
                                                                                                        backgroundColor: '#def8e6',
                                                                                                        borderColor: '#cdf4da',
                                                                                                        borderRadius: '5px',
                                                                                                        padding: '5px 10px'
                                                                                                    }}
                                                                                                >
                                                                                                    <b>Paid</b>
                                                                                                </span>
                                                                                            ) : (
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: '#8e3241',
                                                                                                        backgroundColor: '#fbdde2',
                                                                                                        borderColor: '#f9cbd3',
                                                                                                        borderRadius: '5px',
                                                                                                        padding: '5px 10px'
                                                                                                    }}
                                                                                                >
                                                                                                    <b>{order.supplierCurrencySuffix} {c.supplierCommercialInvoicebalance.toLocaleString()}</b>
                                                                                                </span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    {c.customerCommercialInvoice ? (
                                                                                        <div style={{ flex: '1 1 25%' }}>
                                                                                            {c.customerCommercialInvoice && (
                                                                                                <a
                                                                                                    href={`/Accounting/Customer/${order.customerId}/Account/${order.customerAccountId}/View/Order/${order.customerOrderId}`}
                                                                                                    style={{ textDecoration: 'none', color: '#3eb7ba' }}
                                                                                                >
                                                                                                    <b>#{c.customerCommercialInvoice}</b>
                                                                                                    <span style={{ color: '#a3a3a3' }}>
                                                                                                        {" - " + (c.customerCommercialInvoiceDate ? c.customerCommercialInvoiceDate.split('T')[0] : 'No Date')}
                                                                                                    </span>
                                                                                                </a>
                                                                                            )}
                                                                                           <div style={{ marginTop: '5px', color: '#a3a3a3' }}>
                                                                                                {c.customerCommercialInvoiceTotalPrice <= 0 ? (
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color: '#35834f',
                                                                                                            backgroundColor: '#def8e6',
                                                                                                            borderColor: '#cdf4da',
                                                                                                            borderRadius: '5px',
                                                                                                            padding: '5px 10px'
                                                                                                        }}
                                                                                                    >
                                                                                                        <b>Free</b>
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color: '#8e3241',
                                                                                                            backgroundColor: '#fbdde2',
                                                                                                            borderColor: '#f9cbd3',
                                                                                                            borderRadius: '5px',
                                                                                                            padding: '5px 10px'
                                                                                                        }}
                                                                                                    >
                                                                                                        <b>{order.customerCurrencySuffix} {c.customerCommercialInvoiceTotalPrice.toLocaleString()}</b>
                                                                                                    </span>
                                                                                                )}
                                                                                                {c.profit != 0 && <>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontWeight: 'bold',
                                                                                                            marginLeft: '10px',
                                                                                                            color: c.profit < 0 ? '#ffffff' : '#35834f',
                                                                                                            backgroundColor: c.profit < 0 ? '#f64658' : '#def8e6',
                                                                                                            borderColor: c.profit < 0 ? '#f64658' : '#cdf4da',
                                                                                                            borderRadius: '5px',
                                                                                                            padding: '5px 10px'
                                                                                                        }}
                                                                                                    >
                                                                                                        {c.profit < 0 ? 'Loss' : 'Profit'}: {order.customerCurrencySuffix} {Math.abs(c.profit).toLocaleString()}
                                                                                                    
                                                                                                    </span>
                                                                                                </>} 
                                                                                            </div>

                                                                                        </div>
                                                                                    ) : (
                                                                                        <div style={{ flex: '1 1 25%' }}>No commercial invoices</div>
                                                                                    )}
                                                                                    <div style={{ flex: '1 1 25%' }}>
                                                                                        <div style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                                                            <span style={{ textAlign: 'right', color: '#a3a3a3' }}>
                                                                                                {(c.tracking != null && c.tracking.statusId > 0) &&
                                                                                                    <>
                                                                                                        <span>{c.tracking.shippingLine} - <b>{c.tracking.blReferenceNo}</b></span>
                                                                                                        <br />
                                                                                                        {
                                                                                                            c.tracking.statusId < 50 ?
                                                                                                                <span style={{ ...trackingStatusStyles[c.tracking.statusId], borderRadius: 5, padding: '0 5px' }}>
                                                                                                                    <b>{c.tracking.status} - {c.tracking.arrivalDate}</b></span>
                                                                                                                :
                                                                                                                <>
                                                                                                                    {
                                                                                                                        c.tracking.statusId === 50 &&
                                                                                                                        <span style={{ ...trackingStatusStyles[c.tracking.statusId], borderRadius: 5, padding: '0 5px' }}>
                                                                                                                            <b>{c.tracking.status} - {c.tracking.dischargeDate}</b></span>
                                                                                                                    }
                                                                                                                </>
                                                                                                        }
                                                                                                    </>
                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ flex: '1 1 25%' }}>
                                                                                        <div style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                                                            {
                                                                                                (c.tracking == null || c.tracking.shippingCosts == null || c.tracking.shippingCosts.length === 0)
                                                                                                    ? <span>N/A</span> : <>
                                                                                                        <div>
                                                                                                            {
                                                                                                                c.tracking.shippingCosts.map(shippingCost => (
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            shippingCost.isCredit === false ?
                                                                                                                            <>
                                                                                                                            {
                                                                                                                                shippingCost.isPaid === false ? 
                                                                                                                                <>
                                                                                                                                    {/* {<span style={{ color: '#fbdde2', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer' }}
                                                                                                                                        onClick={(e) => navigate("/Accounting/Forwarders/" + shippingCost.forwarderId + "/Account/" + shippingCost.forwarderAccountId + "/Statment")}
                                                                                                                                    >{shippingCost.shippingCostName} #{shippingCost.invoiceNumber} - <b>{shippingCost.currencySuffix} {shippingCost.amount.toLocaleString()}{shippingCost.isPaid === true && " - PAID"}</b></span >}
                                                                                                                                    <br /> */}
                                                                                                                                    {<span style={{ color: '#8e3241', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer' }}
                                                                                                                                        onClick={(e) => navigate("/Accounting/Forwarders/" + shippingCost.forwarderId + "/Account/" + shippingCost.forwarderAccountId + "/Statment")}
                                                                                                                                    >{shippingCost.shippingCostName} #{shippingCost.invoiceNumber} - <b>{shippingCost.currencySuffix} {shippingCost.amount.toLocaleString()}{shippingCost.isPaid === true && " - PAID"}</b></span >}
                                                                                                                                    <br />
                                                                                                                                </>
                                                                                                                                :
                                                                                                                                <>
                                                                                                                                    {<span style={{ color: '#35834f', backgroundColor: '#def8e6', borderColor: '#cdf4daor', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer' }}
                                                                                                                                        onClick={(e) => navigate("/Accounting/Forwarders/" + shippingCost.forwarderId + "/Account/" + shippingCost.forwarderAccountId + "/Statment")}
                                                                                                                                    >{shippingCost.shippingCostName} #{shippingCost.invoiceNumber} - <b>{shippingCost.currencySuffix} {shippingCost.amount.toLocaleString()}{shippingCost.isPaid === true && " - PAID"}</b></span >}
                                                                                                                                    <br />
                                                                                                                                </>
                                                                                                                                }
                                                                                                                                {/* {<span style={{ color: '#fbdde2', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer' }}
                                                                                                                                    onClick={(e) => navigate("/Accounting/Forwarders/" + shippingCost.forwarderId + "/Account/" + shippingCost.forwarderAccountId + "/Statment")}
                                                                                                                                >{shippingCost.shippingCostName}  #{shippingCost.invoiceNumber} - <b>{shippingCost.currencySuffix} {shippingCost.amount.toLocaleString()}{shippingCost.isPaid === true && " - PAID"}</b></span >}
                                                                                                                                <br /> */}
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            <>
                                                                                                                                {<span style={{ color: '#35834f', backgroundColor: '#def8e6', borderColor: '#cdf4daor', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer' }}
                                                                                                                                    onClick={(e) => navigate("/Accounting/Forwarders/" + shippingCost.forwarderId + "/Account/" + shippingCost.forwarderAccountId + "/Statment")}
                                                                                                                                >{shippingCost.shippingCostName}  #{shippingCost.invoiceNumber} - <b>{shippingCost.currencySuffix} {shippingCost.amount.toLocaleString()}{shippingCost.isCredit === true && " - CREDIT"}</b></span >}
                                                                                                                                <br />
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    </>
                                                                                                                ))
                                                                                                            }
                                                                                                        </div>
                                                                                                        {/* {
                                                                                                            c.tracking.shippingCosts.filter(item => item.isCredit).length === 0 ?
                                                                                                                <div>
                                                                                                                    <span style={{ color: '#35834f', backgroundColor: '#def8e6', borderColor: '#cdf4da', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>Paid</b></span>
                                                                                                                </div>
                                                                                                                :
                                                                                                                <div>
                                                                                                                    {
                                                                                                                        c.tracking.shippingCosts.map(shippingCost => (
                                                                                                                            <>
                                                                                                                                {
                                                                                                                                    shippingCost.isPaid === false &&
                                                                                                                                    <>
                                                                                                                                        {<span style={{ color: '#8e3241', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer' }}
                                                                                                                                            onClick={(e) => navigate("/Accounting/Forwarders/" + shippingCost.forwarderId + "/Account/" + shippingCost.forwarderAccountId + "/Statment")}
                                                                                                                                        >{shippingCost.shippingCostName}  #{shippingCost.invoiceNumber} - <b>{shippingCost.currencySuffix} {shippingCost.amount.toLocaleString()}</b></span >}
                                                                                                                                        <br />
                                                                                                                                    </>
                                                                                                                                }
                                                                                                                            </>
                                                                                                                        ))
                                                                                                                    }
                                                                                                                </div>
                                                                                                        } */}
                                                                                                        </>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <span style={{ color: '#a3a3a3' }}><i>No commercial invoices available</i></span>
                                                                    )}
                                                                </div>


                                                            </div>
                                                        ))
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>


                        </div>
                    </div>




                </>
            )
            }
        </>
    )

}
export default ActiveOrders